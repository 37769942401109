header {
    position: absolute;
    top: 0;
    left: 0;
}

header > div {
    padding: 28px 20px;
    
    @include conduct-tablet {
        padding: 32px;
    }
}

header .btn {
    max-width: 140px;
}