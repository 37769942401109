.Loader-wrapper {
    position: absolute;
    top: calc( 50% - 20px);
    left: calc( 50% - 100px);
    width: 200px;
    height: 40px;
}
.Loader-skin {
    height: 5px;
    background-color: #E8E8E8;
    border-radius: 20px;
    overflow: hidden;
}

.Loader-progress {
    width: 100%;
    height: 100%;
    background-color: #C0C0C0;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: transform 0.95s ease-in-out;
}
.Loader-text {
    margin-top: 12px;
    display: block;
    color: #292929;
    text-align: center;
    font-family: 'Founders Grotesk';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: none;
}