.Portfolio-Description > p{
    @include conduct-desktop {
        text-indent: calc( 25% + 0.5rem);
    }
}
.Portfolio-Lines {
    margin: 48px 0;
}
.Portfolio-Clients-Content {
    color: #7D7D7D;
    margin-bottom: 6rem;
    @include conduct-desktop-large {
        margin-top: 1rem;
        margin-right: 2.5rem;
        margin-bottom: 0;
    }
}
.Portfolio-Clients-List {
    list-style: none;
    padding-left: 0;
    padding-top: 0;
    margin: 0;
}

.Portfolio-Clients-List > li {
    margin-top: 0;
    margin-bottom: 0;
}
.Portfolio-Clients-List a{
    @extend h1;
    font-style: normal;
    text-decoration: none;
    transition: color 1s cubic-bezier(0.23, 1, 0.32, 1);
    position: relative;
    display: inline-block;

    &:hover {
        color: #292929;
        font-style: italic;
        font-weight: 600;
    }
}