.Scene {
    padding: 28px 20px;
    
    @include conduct-tablet {
        padding: 32px;
    }
}
.Scene:last-child {
    margin-bottom: 0;
}

.Scene-Content {
    
    @include conduct-tablet {
        min-height: calc( 100vh - 64px);
    }
}

.Scene-Top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.Scene-Bottom {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: -18vh;
    
    @include conduct-tablet {
        position: absolute;
        margin-top: 0vh;
    }
}