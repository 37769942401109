
.Logos {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
}

.Logos li {
    text-align: center;
    padding: 0;
}

.Logos li:nth-child(2) svg {
    scale: 1.75;
}

.Logos svg {
    max-height: 100px;
    fill: #a0a0a0;
}