*, :before, :after {
  box-sizing: border-box;
}

body, h1, .Portfolio-Clients-List a, h2, h3, .Client a, h4, p, .Approach-Step-Content-Subtitle, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.container-fluid, .container {
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  padding-left: 2rem;
  padding-right: 2rem;
}

.row {
  box-sizing: border-box;
  flex-flow: wrap;
  flex: 0 auto;
  margin-left: -.5rem;
  margin-right: -.5rem;
  display: flex;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-offset-0, .col-xs-offset-1, .col-xs-offset-2, .col-xs-offset-3, .col-xs-offset-4, .col-xs-offset-5, .col-xs-offset-6, .col-xs-offset-7, .col-xs-offset-8, .col-xs-offset-9, .col-xs-offset-10, .col-xs-offset-11, .col-xs-offset-12 {
  box-sizing: border-box;
  flex: none;
  padding-left: .5rem;
  padding-right: .5rem;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%;
}

.col-xs-2 {
  flex-basis: 16.6667%;
  max-width: 16.6667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.3333%;
  max-width: 33.3333%;
}

.col-xs-5 {
  flex-basis: 41.6667%;
  max-width: 41.6667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.3333%;
  max-width: 58.3333%;
}

.col-xs-8 {
  flex-basis: 66.6667%;
  max-width: 66.6667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.3333%;
  max-width: 83.3333%;
}

.col-xs-11 {
  flex-basis: 91.6667%;
  max-width: 91.6667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.6667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333%;
}

.col-xs-offset-5 {
  margin-left: 41.6667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333%;
}

.col-xs-offset-8 {
  margin-left: 66.6667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333%;
}

.col-xs-offset-11 {
  margin-left: 91.6667%;
}

.start-xs {
  text-align: start;
  justify-content: flex-start;
}

.center-xs {
  text-align: center;
  justify-content: center;
}

.end-xs {
  text-align: end;
  justify-content: flex-end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (width >= 48em) {
  .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-offset-0, .col-sm-offset-1, .col-sm-offset-2, .col-sm-offset-3, .col-sm-offset-4, .col-sm-offset-5, .col-sm-offset-6, .col-sm-offset-7, .col-sm-offset-8, .col-sm-offset-9, .col-sm-offset-10, .col-sm-offset-11, .col-sm-offset-12 {
    box-sizing: border-box;
    flex: none;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .col-sm-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .col-sm-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .col-sm-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  .start-sm {
    text-align: start;
    justify-content: flex-start;
  }

  .center-sm {
    text-align: center;
    justify-content: center;
  }

  .end-sm {
    text-align: end;
    justify-content: flex-end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (width >= 64em) {
  .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-offset-0, .col-md-offset-1, .col-md-offset-2, .col-md-offset-3, .col-md-offset-4, .col-md-offset-5, .col-md-offset-6, .col-md-offset-7, .col-md-offset-8, .col-md-offset-9, .col-md-offset-10, .col-md-offset-11, .col-md-offset-12 {
    box-sizing: border-box;
    flex: none;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .col-md-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .col-md-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .col-md-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.6667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.3333%;
  }

  .col-md-offset-5 {
    margin-left: 41.6667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.3333%;
  }

  .col-md-offset-8 {
    margin-left: 66.6667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.3333%;
  }

  .col-md-offset-11 {
    margin-left: 91.6667%;
  }

  .start-md {
    text-align: start;
    justify-content: flex-start;
  }

  .center-md {
    text-align: center;
    justify-content: center;
  }

  .end-md {
    text-align: end;
    justify-content: flex-end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (width >= 75em) {
  .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-offset-0, .col-lg-offset-1, .col-lg-offset-2, .col-lg-offset-3, .col-lg-offset-4, .col-lg-offset-5, .col-lg-offset-6, .col-lg-offset-7, .col-lg-offset-8, .col-lg-offset-9, .col-lg-offset-10, .col-lg-offset-11, .col-lg-offset-12 {
    box-sizing: border-box;
    flex: none;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .col-lg-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .col-lg-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .col-lg-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  .start-lg {
    text-align: start;
    justify-content: flex-start;
  }

  .center-lg {
    text-align: center;
    justify-content: center;
  }

  .end-lg {
    text-align: end;
    justify-content: flex-end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

@font-face {
  font-family: Founders Grotesk;
  src: url("FoundersGrotesk-Light.37d017da.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Founders Grotesk;
  src: url("FoundersGrotesk-Regular.a163adf6.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Founders Grotesk;
  src: url("FoundersGrotesk-RegularItalic.0ee0bd04.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Founders Grotesk;
  src: url("FoundersGrotesk-Semibold.04587b93.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Founders Grotesk;
  src: url("FoundersGrotesk-SemiboldItalic.c09fad51.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Founders Grotesk;
  src: url("FoundersGrotesk-Bold.b2c683e4.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

body, a {
  color: #292929;
  font-family: Founders Grotesk, serif;
}

a {
  color: #292929;
  text-decoration: none;
}

h1, .Portfolio-Clients-List a {
  text-transform: uppercase;
  color: #cecece;
  letter-spacing: -2.48px;
  font-family: Founders Grotesk, serif;
  font-size: 64px;
  font-style: italic;
  font-weight: normal;
  line-height: 120%;
}

@media only screen and (width >= 376px) {
  h1, .Portfolio-Clients-List a {
    font-size: clamp(6rem, 10vw + 2.75rem, 12.4rem);
  }
}

h1 em, .Portfolio-Clients-List a em {
  font-weight: 500;
}

.outline {
  -webkit-text-stroke: 1px #292929;
  -webkit-text-fill-color: #0000;
  letter-spacing: -3px;
  font-weight: 400;
}

hr {
  background: none;
  border-top: 1px solid #dedede;
}

.bottom-border {
  border-bottom: 1px solid #dedede;
}

@media only screen and (width >= 768px) {
  .border-bottom-indent {
    margin-right: 2.5rem;
  }
}

h2 {
  text-transform: capitalize;
  color: #292929;
  letter-spacing: -1.36px;
  font-family: Founders Grotesk, serif;
  font-size: clamp(3rem, 6vw + 1rem, 6.8rem);
  font-style: italic;
  font-weight: 500;
  line-height: 100%;
}

h2.subtitle > span {
  vertical-align: top;
  letter-spacing: 0;
  padding: 0 12px;
  font-size: clamp(1.2rem, 4vw + .5rem, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
}

h3, .Client a {
  letter-spacing: -1.36px;
  text-transform: uppercase;
  color: #b3b3b3;
  font-family: Founders Grotesk, serif;
  font-size: clamp(3rem, 4vw + 2rem, 6.8rem);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

h4 {
  letter-spacing: -2px;
  color: #cecece;
  font-family: Founders Grotesk, serif;
  font-size: clamp(2.6rem, 4vw + 1.8rem, 5.6rem);
  font-style: italic;
  font-weight: 500;
  line-height: 100%;
}

p, .Approach-Step-Content-Subtitle {
  font-family: Founders Grotesk, serif;
  font-size: clamp(1.2rem, 2vw + 1.25rem, 3.2rem);
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

p em, .Approach-Step-Content-Subtitle em {
  color: #009df5;
  font-style: normal;
}

p.smaller, .smaller.Approach-Step-Content-Subtitle {
  color: #7d7d7d;
  font-size: clamp(1.1rem, 2vw + 1rem, 2.2rem);
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

p.smallest, .smallest.Approach-Step-Content-Subtitle {
  font-size: clamp(1.2rem, 2vw + 1rem, 1.6rem);
  line-height: 135%;
}

aside {
  z-index: 10;
  backdrop-filter: blur(2px);
  border-right: 1px solid #dedede;
  width: max(10rem, 8vw + 1rem);
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (width >= 640px) {
  aside {
    display: block;
  }
}

nav {
  width: 50px;
  margin: calc(50vh - 152px) auto 0;
}

nav > a {
  cursor: none;
  pointer-events: none;
  width: 18px;
  height: 18px;
  margin: 0 auto 18px;
  display: block;
  position: relative;
}

nav > a:before {
  content: "";
  opacity: 0;
  border: 1px solid #009df5;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  transition: all .5s;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  scale: .7;
}

nav > a:after {
  content: "";
  background-color: #dedede;
  border-radius: 40px;
  width: 8px;
  height: 8px;
  transition: all .5s;
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
}

nav > a.active:before {
  opacity: 1;
  scale: .7;
}

nav > a.active:after {
  background-color: #009df5;
}

#downarrow {
  opacity: 0;
  pointer-events: none;
  cursor: none;
  border: 1px solid #dedede;
  border-radius: 10px;
  margin-left: -23px;
  padding: 10px 14px 5px;
  text-decoration: none;
  display: block;
  position: absolute;
  bottom: 44px;
  left: 50%;
}

#sound {
  opacity: .5;
  width: 22px;
  margin: auto;
  padding-top: 44px;
  text-decoration: none;
  transition: opacity .5s;
  display: block;
}

#sound > svg > path {
  stroke: #7d7d7d;
}

#sound.playing {
  opacity: 1;
}

header {
  position: absolute;
  top: 0;
  left: 0;
}

header > div {
  padding: 28px 20px;
}

@media only screen and (width >= 480px) {
  header > div {
    padding: 32px;
  }
}

header .btn {
  max-width: 140px;
}

footer {
  padding: 45px 32px;
}

@media only screen and (width >= 640px) {
  footer {
    margin-top: 20vh;
    margin-left: max(10rem, 8vw + 1rem);
    padding: 135px 32px 45px;
  }
}

.Footer-Bottom {
  border-top: 1px solid #dedede;
  margin-top: 40px;
  padding-top: 24px;
  padding-bottom: 24px;
}

@media only screen and (width >= 768px) {
  .Footer-Bottom {
    margin-top: 90px;
  }
}

.Footer-CopyRight {
  text-transform: uppercase;
  font-size: clamp(1.2rem, 1vw + .05rem, 1.4rem);
}

.Footer-OC-Text {
  text-transform: uppercase;
}

@media only screen and (width >= 768px) {
  .Footer-OC-Text {
    padding-left: 25px;
  }
}

.Footer-OC-Img > svg {
  width: 100%;
  min-width: 25px;
  max-width: 35px;
}

.Footer-OC-col2 {
  position: relative;
}

.Footer-OC-Circle svg {
  width: 180px;
}

@media only screen and (width >= 768px) {
  .Footer-OC-Circle {
    position: absolute;
    top: 60px;
    left: 25px;
  }
}

.Footer-List .row:nth-child(2) {
  margin-top: 2rem;
}

@media only screen and (width >= 768px) {
  .Footer-List .row:nth-child(2) {
    margin-top: 52px;
  }
}

.Footer-List-Item p, .Footer-List-Item .Approach-Step-Content-Subtitle {
  text-transform: uppercase;
  font-size: clamp(1.2rem, 1vw + .05rem, 1.4rem);
  line-height: 135%;
}

.Footer-List-Item p:first-child, .Footer-List-Item .Approach-Step-Content-Subtitle:first-child {
  color: #7d7d7d;
}

.hide {
  display: none !important;
}

@media only screen and (width <= 1024px) {
  .hide-on-tablet {
    display: none;
  }
}

#hero h1, #hero .Portfolio-Clients-List a, .Portfolio-Clients-List #hero a {
  color: #292929;
  font-weight: 600;
}

#hero h1 .outline, #hero .Portfolio-Clients-List a .outline, .Portfolio-Clients-List #hero a .outline {
  font-style: normal;
}

#hero #tagline {
  letter-spacing: 0;
  text-transform: capitalize;
  vertical-align: middle;
  margin-top: 8rem;
  margin-bottom: calc(6vw + .01rem);
  padding: 0 clamp(1rem, 2vw + 2rem, 8rem);
  font-size: clamp(1.3rem, 2vw + .01rem, 1.8rem);
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

#hero #tagline svg {
  position: absolute;
  top: -25px;
  scale: .5;
}

#hero #tagline svg:first-child {
  left: 0;
}

#hero #tagline svg:last-child {
  right: 0;
}

@media only screen and (width >= 376px) {
  #hero #tagline {
    margin-top: 0;
  }
}

@media only screen and (width >= 1025px) {
  #hero #tagline {
    margin-top: calc(1vw + 3rem);
    position: absolute;
    left: min(58vw, 65rem);
  }
}

#hero .Scene-Content {
  min-height: calc(100vh - 40px);
}

@media only screen and (width >= 480px) {
  #hero .Scene-Content {
    min-height: calc(100vh - 64px);
  }
}

#hero .Scene-Bottom {
  bottom: -120px;
}

#logo {
  width: 160px;
  height: 60px;
  padding-top: 6px;
  display: block;
}

@media only screen and (width >= 480px) {
  #logo {
    width: 180px;
  }
}

#logo svg {
  fill: #292929;
}

.AboutUs .bg {
  pointer-events: none;
  opacity: .15;
  z-index: -10;
  background: url("circle-2x.09db7449.png") 0 / 100% no-repeat;
  width: 790px;
  height: 757px;
  display: none;
  position: absolute;
  top: calc(50% - 220px);
  left: 0;
}

.AboutUs .bg:after {
  content: "";
  mix-blend-mode: lighten;
  z-index: 0;
  background: linear-gradient(145deg, #f0f0f000 40%, #f0f0f0 100%);
  border-radius: 100%;
  width: 790px;
  height: 757px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width >= 1025px) {
  .AboutUs .bg {
    display: block;
  }
}

.AboutUs-Description, .AboutUs-Description .btn, .AboutUs-Description p, .AboutUs-Description .Approach-Step-Content-Subtitle {
  margin-bottom: 48px;
}

.AboutUs-Description {
  margin-top: 10px;
}

.AboutUs-Description .btn {
  width: 148px;
}

.Services h2 {
  letter-spacing: -2.48px;
  text-transform: uppercase;
  font-size: clamp(3rem, 6vw + 1.25rem, 12.4rem);
  font-style: italic;
  font-weight: 600;
  line-height: 120%;
}

.Services h2 > span {
  margin-bottom: 1.5rem;
  display: block;
}

@media only screen and (width >= 480px) {
  .Services h2 > span {
    margin-bottom: 2rem;
  }
}

.Service {
  color: #cecece;
  letter-spacing: -2.48px;
  font-style: normal;
  font-weight: normal;
  transition: color 1s cubic-bezier(.23, 1, .32, 1);
  position: relative;
  display: inline-block !important;
}

.Service:after {
  content: "";
  border: 2px solid #0000;
  border-radius: 40px;
  width: calc(100% + 60px);
  height: 100%;
  transition: all 1s cubic-bezier(.23, 1, .32, 1);
  position: absolute;
  top: -16%;
  left: -30px;
  transform: scale(1.1);
}

@media only screen and (width >= 480px) {
  .Service:after {
    width: calc(100% + 80px);
    left: -40px;
  }
}

.Service:hover {
  color: #292929;
  font-style: italic;
  font-weight: 600;
}

.Service:hover:after {
  border-color: #009df5;
  transform: scale(1);
}

.Services .Service:nth-child(2) {
  margin-left: 2%;
}

.Services .Service:nth-child(3) {
  margin-left: 20%;
}

.Services .Service:nth-child(4) {
  margin-left: 32%;
}

.Services .Service:nth-child(5) {
  margin-left: 5%;
}

.Services h2 > span:last-child {
  margin-left: 35%;
}

@media only screen and (width >= 768px) {
  .Portfolio-Description > p, .Portfolio-Description > .Approach-Step-Content-Subtitle {
    text-indent: calc(25% + .5rem);
  }
}

.Portfolio-Lines {
  margin: 48px 0;
}

.Portfolio-Clients-Content {
  color: #7d7d7d;
  margin-bottom: 6rem;
}

@media only screen and (width >= 1025px) {
  .Portfolio-Clients-Content {
    margin-top: 1rem;
    margin-bottom: 0;
    margin-right: 2.5rem;
  }
}

.Portfolio-Clients-List {
  margin: 0;
  padding-top: 0;
  padding-left: 0;
  list-style: none;
}

.Portfolio-Clients-List > li {
  margin-top: 0;
  margin-bottom: 0;
}

.Portfolio-Clients-List a {
  font-style: normal;
  text-decoration: none;
  transition: color 1s cubic-bezier(.23, 1, .32, 1);
  display: inline-block;
  position: relative;
}

.Portfolio-Clients-List a:hover {
  color: #292929;
  font-style: italic;
  font-weight: 600;
}

.Approach-Description {
  padding-bottom: 25px;
}

.Approach-Steps {
  flex-direction: column-reverse;
  position: relative;
}

@media only screen and (width >= 768px) {
  .Approach-Steps {
    flex-direction: row;
  }
}

.Approach-Steps ol {
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.Approach-Step {
  padding: 20px 0;
}

@media only screen and (width >= 768px) {
  .Approach-Step {
    max-width: 500px;
    min-height: 50vh;
    max-height: 800px;
    padding: 40px 6rem 40px 0;
  }
}

.Approach-Step-Content-Subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 18px;
}

.Approach-Step-Content-Title {
  color: #2d2d2d;
  margin-bottom: 1rem;
  transition: color .65s;
}

@media only screen and (width >= 480px) {
  .Approach-Step-Content-Title {
    margin-bottom: 15px;
  }
}

.Approach-Step-Image {
  text-align: center;
  background-color: #d7d7d7d9;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  height: calc(1vw + 50rem);
  margin: auto;
  transition: opacity .65s;
  display: flex;
  overflow: hidden;
}

.Approach-Step-Image svg {
  opacity: 0;
  width: 70%;
  position: absolute;
}

.Approach-Step-Image > svg:first-child {
  opacity: 1;
}

.Clients-List {
  margin-top: 55px;
  padding-left: 0;
  list-style-type: none;
}

.Client a {
  margin-bottom: 10px;
  text-decoration: none;
  transition: color 1s cubic-bezier(.23, 1, .32, 1);
  display: inline-block;
}

.Client a:hover {
  color: #2d2d2d;
  font-style: italic;
}

.Clients {
  overflow: hidden;
}

.Clients .bg {
  pointer-events: none;
  opacity: .2;
  z-index: -10;
  background: url("circle-2x.09db7449.png") 0 / 100% no-repeat;
  width: 850px;
  height: 1000px;
  display: none;
  position: absolute;
  top: calc(50% - 550px);
  right: -172px;
}

.Clients .bg:after {
  content: "";
  mix-blend-mode: lighten;
  z-index: 0;
  background: linear-gradient(145deg, #f0f0f000 0%, #f0f0f0 60%);
  border-radius: 100%;
  width: 852px;
  height: 826px;
  display: block;
  position: absolute;
  top: 86px;
  left: 0;
}

@media only screen and (width >= 1025px) {
  .Clients .bg {
    display: block;
  }
}

.Clients-List li:first-child {
  margin-left: -20%;
}

.Clients-List li:nth-child(2), .Clients-List li:nth-child(3) {
  margin-left: 0%;
}

.Clients-List li:nth-child(4) {
  margin-left: -25%;
}

.Clients-List li:nth-child(5) {
  margin-left: 0%;
}

.Clients-List li:nth-child(6) {
  margin-left: -22%;
}

.Clients-List li:nth-child(7), .Clients-List li:nth-child(8) {
  margin-left: 0%;
}

.Clients-List li:nth-child(9) {
  margin-left: -30%;
}

.Intro > .row {
  height: 100%;
}

.Intro-Excerpt {
  animation: 3s 2s infinite pulse;
  position: absolute;
  bottom: 4rem;
}

.Intro-Excerpt p, .Intro-Excerpt .Approach-Step-Content-Subtitle {
  color: #b3b3b3;
  font-size: clamp(1.1rem, 1vw + 1rem, 1.6rem);
}

@media only screen and (width >= 480px) {
  .Intro-Excerpt {
    display: none;
  }
}

@keyframes pulse {
  0% {
    opacity: .3;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: .3;
  }
}

.Intro-Quote {
  visibility: visible;
  opacity: 1;
  font-size: 14px;
  transition: opacity 1s;
}

#TurnSoundOn {
  text-align: center;
  width: 200px;
  margin: 0 auto 10px;
}

#BypassSound {
  border-bottom: 1px solid #222;
  margin-top: 1rem;
  font-size: clamp(1.1rem, 1vw + 1rem, 1.6rem);
  display: inline-block;
}

.btn {
  text-align: center;
  border: 1px solid #009df5;
  border-radius: 20px;
  padding: 12px 5px 8px;
  font-family: Founders Grotesk;
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
  text-decoration: none;
  transition: background-color 1s cubic-bezier(.23, 1, .32, 1), color 1s cubic-bezier(.23, 1, .32, 1);
  display: block;
}

.btn:hover {
  color: #009df5;
}

.btn:focus {
  border-color: #5eb5f9;
}

.btn:active {
  color: #0089d7;
  border-color: #0089d7;
}

.btn.btn-filled {
  color: #fff;
  background-color: #009df5;
}

.btn.btn-filled:hover, .btn.btn-filled:focus {
  background-color: #5eb5f9;
}

.btn.btn-filled:active {
  background-color: #3b87d1;
  border-color: #3b87d1;
}

.btn + conduct-tablet {
  padding: 16px 10px 10px;
}

.Cursor-Main, .Cursor-Border {
  will-change: transform;
  z-index: 1000;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 50%;
  transition: background 1s, height .3s, width .3s;
  position: fixed;
}

.Cursor-Main {
  background-color: #292929f2;
  width: 10px;
  height: 10px;
  display: none;
  top: -5px;
  left: -5px;
}

.Cursor-Border {
  border: 1px solid #00000059;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: all 1.45s cubic-bezier(.23, 1, .32, 1);
  display: none;
  top: -20px;
  left: -20px;
}

.Cursor-Main.cursor--initialized, .Cursor-Border.cursor--initialized {
  display: block;
}

.Cursor-Main.cursor--off-screen, .Cursor-Border.cursor--off-screen {
  display: none;
}

.Cursor-Main.cursor--focused, .Cursor-Main.cursor--focused-volume {
  background-color: #29292980;
  width: 30px;
  height: 30px;
  top: -15px;
  left: -15px;
}

.Cursor-Main .Cursor-Main--sound {
  color: #292929;
  font-size: 1.2rem;
  display: none;
  position: relative;
}

.Cursor-Main.cursor--focused-volume .Cursor-Main--sound {
  display: block;
}

.Cursor-Main .Cursor-Main--sound > span {
  visibility: hidden;
  text-align: center;
  opacity: 0;
  width: 100px;
  transition: all 1.45s cubic-bezier(.23, 1, .32, 1);
  position: absolute;
  top: 4rem;
  left: -35px;
}

.Cursor-Main .Cursor-Main--sound > span.show {
  visibility: visible;
  opacity: 1;
}

.Cursor-Main.cursor--clicking {
  background-color: #009df5bf;
}

.Cursor-Main.cursor--clicking.cursor--focused-volume .Cursor-Main--sound {
  color: #009df5;
}

.Cursor-Border.cursor--clicking {
  border-color: #009df5bf;
}

.Scene {
  padding: 28px 20px;
}

@media only screen and (width >= 480px) {
  .Scene {
    padding: 32px;
  }
}

.Scene:last-child {
  margin-bottom: 0;
}

@media only screen and (width >= 480px) {
  .Scene-Content {
    min-height: calc(100vh - 64px);
  }
}

.Scene-Top {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Scene-Bottom {
  width: 100%;
  margin-top: -18vh;
  position: relative;
  bottom: 0;
  left: 0;
}

@media only screen and (width >= 480px) {
  .Scene-Bottom {
    margin-top: 0;
    position: absolute;
  }
}

.Loader-wrapper {
  width: 200px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 100px);
}

.Loader-skin {
  background-color: #e8e8e8;
  border-radius: 20px;
  height: 5px;
  overflow: hidden;
}

.Loader-progress {
  transform-origin: 0 0;
  background-color: silver;
  width: 100%;
  height: 100%;
  transition: transform .95s ease-in-out;
  transform: scaleX(0);
}

.Loader-text {
  color: #292929;
  text-align: center;
  margin-top: 12px;
  font-family: Founders Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: none;
}

.Logos {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.Logos li {
  text-align: center;
  padding: 0;
}

.Logos li:nth-child(2) svg {
  scale: 1.75;
}

.Logos svg {
  fill: #a0a0a0;
  max-height: 100px;
}

.Carousel {
  perspective: 2000px;
  transform-style: preserve-3d;
  opacity: 0;
  pointer-events: none;
  padding: 0;
  list-style-type: none;
  display: flex;
  position: absolute;
  top: 0;
}

.Carousel-Item {
  text-align: center;
  background-color: #d0d0d0;
  margin: 0 1rem;
  padding: 0;
  -webkit-mask-image: url("portfoliomask.3946d82c.svg");
  mask-image: url("portfoliomask.3946d82c.svg");
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.Carousel-Item > img {
  width: 100%;
  margin: 0 auto;
}

.Carousel-Item:first-child, .Carousel-Item:nth-child(3) {
  z-index: -5;
  transition: transform 2s cubic-bezier(.23, 1, .32, 1);
  transform: translateX(100px);
}

.Carousel-Item:nth-child(2) {
  z-index: 0;
}

.Carousel-Item:nth-child(3) {
  transform: translateX(-100px);
}

.Carousel {
  transition: transform 1s cubic-bezier(.23, 1, .32, 1), opacity 1s cubic-bezier(.23, 1, .32, 1);
  transform: translateY(-15px);
}

.Carousel.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateX(0);
}

.Carousel.active .Carousel-Item:first-child, .Carousel.active .Carousel-Item:nth-child(3) {
  transform: translateX(0);
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}

body {
  background-color: #f0f0f0;
  position: relative;
  overflow-x: hidden;
}

::selection {
  background: #ccc;
}

.lenis-scrolling #MainAnimator {
  opacity: 1;
  filter: blur();
}

#MainAnimator {
  filter: blur(4px);
  z-index: -100;
  opacity: .4;
  visibility: visible;
  width: 100vw;
  height: 100vh;
  transition: opacity 1.75s, filter 1.75s;
  position: fixed;
  top: 0;
}

video {
  display: none;
}

header, main {
  width: 100%;
}

@media only screen and (width >= 640px) {
  header, main {
    width: calc(100% + -1 * max(10rem, 8vw + 1rem));
    margin-left: max(10rem, 8vw + 1rem);
  }
}

header {
  z-index: 10;
}

main {
  z-index: 0;
}

.container {
  max-width: 1295px;
  position: relative;
}

.container-fx {
  z-index: 10;
  width: 92%;
  max-width: 2880px;
  position: fixed;
  top: 0;
  left: 4%;
}

#Loader {
  z-index: 99;
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#app {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

.Intro {
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.Intro-Excerpt {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1.5s 1s, transform 1.25s 1s;
  transform: translateY(60px);
}

#app, #Loader, .Intro {
  transition: opacity 1.5s;
}

.js.loading #app {
  visibility: hidden;
  opacity: 0;
}

.js.loading body {
  overflow: hidden;
}

.js.loading #Loader {
  visibility: visible;
  opacity: 1;
  display: block;
}

.js.loading-animateIn #app {
  visibility: visible;
  opacity: 1;
}

.js.loading-animateIn #Loader, .js.scene-intro aside, .js.scene-intro footer, .js.scene-intro main, .js.scene-intro header {
  visibility: hidden;
  opacity: 0;
}

.js.scene-intro #MainAnimator {
  opacity: 1;
}

.js.scene-intro .Intro {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.js.scene-intro .Intro-Excerpt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.js.scene-intro.scene-intro-transition .Intro {
  opacity: 0;
}

.js.scene-main.scene-transition .Intro {
  opacity: 0;
  visibility: visible;
}

.js.loading-done #Loader {
  opacity: 0;
}

.js.loading-complete body {
  overflow: auto;
}

.js.loading-complete #Loader {
  display: none;
}
/*# sourceMappingURL=index.6ff8eb1e.css.map */
