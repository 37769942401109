aside {
    width: $header_width;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    border-right: 1px solid #DEDEDE;
    z-index: 10;
    display: none;
    // background-color: rgba(240, 240, 240, 0.25);
    backdrop-filter: blur(2px);

    @include conduct-tablet-landscape {
        display: block;
    }
}