
#hero h1 {
    font-weight: 600;
    color: #292929;
}
#hero h1 .outline {
    font-style: normal;
}
#hero #tagline {
    font-size: 18px;
    font-size: clamp(1.3rem, 2vw + 0.01rem, 1.8rem);
    letter-spacing: 0;
    font-weight: normal;
    text-transform: capitalize;
    padding: 0 80px;
    padding: 0 clamp(1rem, 2vw + 2rem, 8rem);
    line-height: 18px;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    font-style: normal;
    margin-bottom: calc(6vw + 0.01rem);
    margin-top: 8rem;

    svg {
        position: absolute;
        scale: 0.5;
        top: -25px;
        &:first-child {
            left: 0;
        }
        &:last-child {
            right: 0;
        }
    }

    @include conduct-mobile-large {
        margin-top: 0;
    }

    @include conduct-desktop-large {
        position: absolute;
        margin-top: calc(1vw + 3rem );
        left: clamp(58vw,45vw,65rem);
    }

}
#hero .Scene-Content {
    min-height: calc( 100vh - 40px);
    @include conduct-tablet {
        min-height: calc(100vh - 64px);
    }
}
#hero .Scene-Bottom {
    bottom: -120px;
}

#logo {
    display: block;
    padding-top: 6px;
    width: 160px;
    height: 60px;
    @include conduct-tablet {
        width: 180px;
    }
}
#logo svg {
    fill: #292929;
}