.AboutUs .Scene-Content {
}
.AboutUs .bg {
    background: url('./../images/ui/circle-2x.png') no-repeat center left;
    background-size: 100%;
    width: 790px;
    height: 757px;
    position: absolute;
    top: calc(50% - 220px);
    left: 0px;
    pointer-events: none;
    opacity: 0.15;
    z-index: -10;
    &::after {
        content: '';
        mix-blend-mode: lighten;
        background: linear-gradient(145deg, rgb(240, 240, 240,0) 40%,  rgb(240, 240, 240, 1) 100%);
        display: block;
        border-radius: 100%;
        width: 790px;
        height: 757px;
        z-index: 0;
        top: 0;
        left: 0;
        position: absolute;
    }
    display: none;
    @include conduct-desktop-large {
        display: block;
    }
}

.AboutUs-Description,
.AboutUs-Description .btn,
.AboutUs-Description p
{
    margin-bottom: 48px;
}

.AboutUs-Description {
    margin-top: 10px;
}

.AboutUs-Description .btn {
    width: 148px;
}