@import 'libs/reset';
@import 'libs/lenis';
@import 'libs/flexgrid';
@import 'base/_all';
@import 'sections/_all';
@import 'modules/all';

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
}
body {
    background-color: #F0F0F0;
    position: relative;
    overflow-x: hidden;
}

::selection {
    background: #cccccc;
}

.lenis-scrolling #MainAnimator {
    opacity: 1;
    filter: blur(0);
}
#MainAnimator {
    filter: blur(4px);
    position: fixed;
    z-index: -100;
    top: 0;
    width: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.4;
    visibility: visible;
    transition: opacity 1.75s, filter 1.75s;
}
video {
    display: none;
}
header,
main {
    width: 100%;
    @include conduct-tablet-landscape {
        margin-left: $header_width;
        width: calc(100% - $header_width);
    }
}

header {
    z-index: 10;
}

main {
    z-index: 0;
}

.container {
    position: relative;
    max-width: 1295px;
}
.container-fx {
    position: fixed;
    top: 0;
    left: 4%;
    z-index: 10;
    width: 92%;
    max-width: 2880px;
}

#Loader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 99;
    top: 0;
    left: 0;
    display: none;
}

#app {
    z-index: 0;
    position: relative;
    overflow: hidden;
}

.Intro {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
}

.Intro-Excerpt {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1.5s, transform 1.25s;
    transition-delay: 1s;
    transform: translateY(60px);
}


#app,
#Loader,
.Intro {
    transition: opacity 1.5s;
}

.js.loading {
    #app {
        visibility: hidden;
        opacity: 0;
    }
    body {
        overflow: hidden;
    }
    #Loader {
        display: block;
        visibility: visible;
        opacity: 1;
    }
}

.js.loading-animateIn {
    #app {
        visibility: visible;
        opacity: 1;
    }
    #Loader {
        visibility: hidden;
        opacity: 0;
    }
}

.js.scene-intro {
    aside, footer, main, header {
        visibility: hidden;
        opacity: 0;
    }
    #MainAnimator {
        opacity: 1;
    }
    .Intro {
        opacity: 1;
        visibility: visible;
        z-index: 100;
    }
    .Intro-Excerpt {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }

    &.scene-intro-transition {
        .Intro {
            opacity: 0;
        }
    }
}
.js.scene-main.scene-transition {
    .Intro {
        opacity: 0;
        visibility: visible;
    }
}

.js.loading-done {
    #Loader {
        opacity: 0;
    }
}
.js.loading-complete {
    body {
        overflow: auto;
    }
    #Loader {
        display: none;
    }
}