@font-face {
    font-family: 'Founders Grotesk';
    src: url('./../fonts/founders_grotesk/FoundersGrotesk-Light.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Founders Grotesk';
    src: url('./../fonts/founders_grotesk/FoundersGrotesk-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Founders Grotesk';
    src: url('./../fonts/founders_grotesk/FoundersGrotesk-RegularItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Founders Grotesk';
    src: url('./../fonts/founders_grotesk/FoundersGrotesk-Semibold.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Founders Grotesk';
    src: url('./../fonts/founders_grotesk/FoundersGrotesk-SemiboldItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Founders Grotesk';
    src: url('./../fonts/founders_grotesk/FoundersGrotesk-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}