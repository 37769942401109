.Services h2 {
    font-size: clamp(3rem, 6vw + 1.25rem, 12.4rem);
    line-height: 120%;
    font-style: italic;
    font-weight: 600;
    letter-spacing: -2.48px;
    text-transform: uppercase;

    // @include conduct-tablet-landscape {
    //     font-size: clamp(4rem, 6vw + 2.4rem, 12.4rem);
    // }
}

.Services h2 > span {
    display: block;
    margin-bottom: 1.5rem;
    @include conduct-tablet {
        margin-bottom: 2rem;
    }
}

.Service {
    color: #CECECE;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -2.48px;
    position: relative;
    display: inline-block !important;
    transition: color 1s cubic-bezier(0.23, 1, 0.32, 1);
    
    &:after {
        content: '';
        width: calc( 100% + 60px);
        height: 100%;
        position: absolute;
        border-radius: 40px;
        border: transparent 2px solid;
        top: -16%;
        left: -30px;
        transform: scale(1.1);
        transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);

        @include conduct-tablet {
            width: calc( 100% + 80px);
            left: -40px;
        }
        // @include conduct-tablet-landscape {
        //     top: -18px;
        // }
        // @include conduct-desktop {
        //     top: -20px;
        // }
        // @include conduct-desktop-large {
        //     top: -24px;
        // }
    }
    &:hover {
        color: #292929;
        font-weight: 600;
        font-style: italic;
        &:after {
            border-color: #009DF5;
            transform: scale(1);
        }
    }
}

.Services .Service:nth-child(2) {
    margin-left: 2%;
}
.Services .Service:nth-child(3) {
    margin-left: 20%;
}
.Services .Service:nth-child(4) {
    margin-left: 32%;
}
.Services .Service:nth-child(5) {
    margin-left: 5%;
}

.Services h2 > span:last-child {
    margin-left: 35%;
}