.Carousel {
    list-style-type: none;
    padding: 0;
    display: flex;
    perspective: 2000px;
    transform-style: preserve-3d;
    position: absolute;
    top: 0;
    opacity: 0;
    pointer-events: none;
}

.Carousel-Item {
    text-align: center;
    mask-image: url('../images/ui/portfoliomask.svg');
    -webkit-mask-image: url('../images/ui/portfoliomask.svg');
    mask-size: 100%;
    -webkit-mask-size: 100%;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    padding: 0;
    margin: 0 1rem;
    background-color: #D0D0D0;
}
.Carousel-Item > img {
    width: 100%;
    margin: 0 auto;
}


.Carousel-Item {
    &:nth-child(1),
    &:nth-child(3) {
        transform: translateX(100px);
        transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1);;
        z-index: -5;
    }
    &:nth-child(2) {
        z-index: 0;
    }
    &:nth-child(3) {
        transform: translateX(-100px);
    }
}
.Carousel {
    transform: translateY(-15px);
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), opacity 1s cubic-bezier(0.23, 1, 0.32, 1);
    &.active {
        // display: flex !important; // override utility class
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: translateX(0px);

        // fan out
        & .Carousel-Item {
            &:nth-child(1) {
                transform: translateX(0);
            }
            &:nth-child(3) {
                transform: translateX(0);
            }
        }
    }
}