@mixin conduct-mobile-large {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    @media only screen and (min-width: 376px) { @content; }
}

@mixin conduct-tablet {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    @media only screen and (min-width: 480px) { @content; }
}
@mixin conduct-tablet-landscape {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    @media only screen and (min-width: 640px) { @content; }
}
@mixin conduct-desktop {
    /* big landscape tablets, laptops, and desktops */
    @media only screen and (min-width: 768px) { @content; }
}
@mixin conduct-desktop-large {
    /* big landscape tablets, laptops, and desktops */
    @media only screen and (min-width: 1025) { @content; }
}