.Intro {
    > .row {
        height: 100%;
    }   
}
.Intro-Excerpt {
    position: absolute;
    bottom: 4rem;
    animation-name: pulse;
    animation: pulse 3s infinite;
    animation-delay: 2s;
    p {
        font-size: clamp(1.1rem, 1vw + 1rem, 1.6rem);
        color: #b3b3b3;
    }
    @include conduct-tablet {
        display: none;
    }
}
@keyframes pulse {
    0% {opacity: 0.3;}
    70% {opacity: 1;}
    100% {opacity: 0.3;}
}

.Intro-Quote {
    visibility: visible;
    opacity: 1;
    font-size: 14px;
    transition: opacity 1s;
}

#TurnSoundOn {
    width: 200px;
    text-align: center;
    margin: 0 auto 10px;
}

#BypassSound {
    margin-top: 1rem;
    font-size: clamp(1.1rem, 1vw + 1rem, 1.6rem);
    display: inline-block;
    border-bottom: 1px solid #222;
}