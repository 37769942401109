.Approach-Description {
    padding-bottom: 25px;
}
.Approach-Steps {
    position: relative;
    flex-direction: column-reverse;
    @include conduct-desktop {
        flex-direction: row;
    }
}

.Approach-Steps ol{
    padding-left: 0;
    text-decoration: none;
    list-style-type: none;
}

.Approach-Step {
    padding: 20px 0 20px 0;

    @include conduct-desktop {
        padding: 40px 6rem 40px 0;
        max-width: 500px;
        min-height: 50vh;
        max-height: 800px;
    }
}

.Approach-Step-Content-Subtitle {
    @extend p;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 20px;
}
.Approach-Step-Content-Title {
    transition: 0.65s color;
    color: #2D2D2D;
    margin-bottom: 1rem;
    @include conduct-tablet {
        margin-bottom: 15px;
    }
}

.Approach-Step-Image {
    background-color: rgba(215, 215, 215, 0.85);
    overflow: hidden;
    border-radius: 20px;
    transition: 0.65s opacity;
    margin: auto;
    text-align: center;
    height: calc(1vw + 50rem);
    justify-content: center;
    align-items: center;
    display: flex;
}

.Approach-Step-Image svg {
    width: 70%;
    opacity: 0;
    position: absolute;
}

.Approach-Step-Image > svg:nth-child(1) {
    opacity: 1;
}