body,
a {
    color: #292929;
    font-family: 'Founders Grotesk', serif;
}
a {
    text-decoration: none;
    color: #292929;
}
h1 {
    font-family: 'Founders Grotesk', serif;
    line-height: 140px;
    font-size: 64px;
    line-height: 120%;
    font-weight: normal;
    font-style: italic;
    text-transform: uppercase;
    color: #CECECE;
    letter-spacing: -2.48px;

    @include conduct-mobile-large {
        font-size: clamp(6rem, 10vw + 2.75rem, 12.4rem);
    }

    em {
        font-weight: 500;
    }
}
.outline {
	-webkit-text-stroke: 1px #292929;
	-webkit-text-fill-color: rgba(0,0,0,0);
    font-weight: 400;
    letter-spacing: -3px;
}
hr {
    background: none;
    border-top: solid 1px #DEDEDE;
}

.bottom-border {
    border-bottom: solid 1px #DEDEDE;
}
.border-bottom-indent {
    @include conduct-desktop {
        margin-right: 2.5rem;
    }

}
h2 {
    font-family: 'Founders Grotesk', serif;
    font-size: 68px;
    font-size: clamp(3rem, 6vw + 1rem, 6.8rem);
    line-height: 100%;
    font-weight: 500;
    font-style: italic;
    text-transform: capitalize;
    color: #292929;
    letter-spacing: -1.36px;

    &.subtitle > span {
        vertical-align: top;
        font-size: 20px;
        font-size: clamp(1.2rem, 4vw + 0.5rem, 2rem);
        padding: 0 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: inline-block;
        letter-spacing: 0;
        
    }
}

h3 {
    font-family: 'Founders Grotesk', serif;
    font-size: 68px;
    font-size: clamp(3rem, 4vw + 2rem, 6.8rem);
    line-height: 100%;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -1.36px;
    text-transform: uppercase;
    color: #B3B3B3;
}

h4 {
    font-family: 'Founders Grotesk', serif;
    font-size: 56px;
    font-size: clamp(2.6rem, 4vw + 1.8rem, 5.6rem);
    font-style: italic;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -2px;
    color: #CECECE;
}

p {
    font-family: 'Founders Grotesk', serif;
    font-size: 32px;
    font-size: clamp(1.2rem, 2vw + 1.25rem, 3.2rem);
    font-style: normal;
    font-weight: 400;
    line-height: 135%;

    em {
        color: #009DF5;
        font-style: normal;
    }
}

p.smaller {
    font-size: 20px;
    font-size: clamp(1.1rem, 2vw + 1rem, 2.2rem);
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 27px */
    color: #7D7D7D;
}

p.smallest {
    font-size: 16px;
    font-size: clamp(1.2rem, 2vw + 1rem, 1.6rem);
    line-height: 135%;
}