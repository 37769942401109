footer {

    @include conduct-tablet-landscape {
        margin-left: $header_width;
        margin-top: 20vh;
        padding: 135px 32px 45px;
    }
    padding: 45px 32px 45px;
}

.Footer-Bottom {
    border-top: 1px solid #DEDEDE;
    padding-top: 24px;
    margin-top: 40px;
    padding-bottom: 24px;

    @include conduct-desktop {
        margin-top: 90px;
    }
}

.Footer-CopyRight {
    font-size: clamp(1.2rem, 1vw + 0.05rem, 1.4rem);
    text-transform: uppercase;
}

.Footer-OC-Text {
    text-transform: uppercase;
    @include conduct-desktop{
        padding-left: 25px;
    }
}

.Footer-OC-Img {
    > svg {
        width: 100%;
        max-width: 35px;
        min-width: 25px;
    }
}

.Footer-OC-col2 {
    position: relative;
}
.Footer-OC-Circle {
    svg {
        width: 180px;
    }
    
    @include conduct-desktop {
        position: absolute;
        left: 25px;
        top: 60px;
    }
}

.Footer-List .row:nth-child(2) {
    margin-top: 2rem;
    @include conduct-desktop {
        margin-top: 52px;
    }
}
.Footer-List-Item p {
    font-size: clamp(1.2rem, 1vw + 0.05rem, 1.4rem);
    line-height: 135%;
    text-transform: uppercase;
}

.Footer-List-Item p:first-child {
    color: #7D7D7D;
}