.btn {
    padding: 12px 5px 8px;
    display: block;
    border-radius: 20px;
    border: 1px solid #009DF5;
    text-decoration: none;
    font-family: 'Founders Grotesk';
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    transition: 1s cubic-bezier(0.23, 1, 0.32, 1);
    transition-property: background-color, color;

    &:hover {
        color: #009DF5;
    }
    &:focus {
        border-color: #5EB5F9;
    }
    &:active {
        border-color: #0089D7;
        color: #0089D7;
    }


    &.btn-filled {
        background-color: #009DF5;
        color: #FFF;
        &:hover {
            background-color: #5EB5F9;
        }
        &:focus {
            background-color: #5EB5F9;
        }
        &:active {
            background-color: #3B87D1;
            border-color: #3B87D1;
        }
    }

    +conduct-tablet {
        padding: 16px 10px 10px;
    }
}