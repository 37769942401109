.Cursor-Main,
.Cursor-Border {
    transition: background 1s, height 300ms, width 300ms;
    will-change: transform;
    border-radius: 50%;
    z-index: 1000;
    position: fixed;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Cursor-Main {
    background-color:rgba(41,41,41,0.95);
    width: 10px;
    height: 10px;
    top: -5px;
    left: -5px;
    display: none;
}

.Cursor-Border {
    border: 1px solid rgba(0,0,0,0.35);
    border-radius: 50%;
    transition: all 1450ms cubic-bezier(0.23, 1, 0.32, 1);
    width: 40px;
    height: 40px;
    top: -20px;
    left: -20px;
    display: none;
}

.Cursor-Main.cursor--initialized,
.Cursor-Border.cursor--initialized {
    display: block;
}

.Cursor-Main.cursor--off-screen,
.Cursor-Border.cursor--off-screen  {
    display: none;
}

// hover event
.Cursor-Main.cursor--focused,
.Cursor-Main.cursor--focused-volume {
    width: 30px;
    height: 30px;
    top: -15px;
    left: -15px;
    background-color:rgba(41,41,41,0.5);
}

// volume text
.Cursor-Main .Cursor-Main--sound {
    position: relative;
    display: none;
    font-size: 1.2rem;
    color: rgb(41,41,41);
}
.Cursor-Main.cursor--focused-volume .Cursor-Main--sound {
    display: block;
}

.Cursor-Main .Cursor-Main--sound > span {
    visibility: hidden;
    position: absolute;
    text-align: center;
    width: 100px;
    top: 4rem;
    left: -35px;
    opacity: 0;
    transition: all 1450ms cubic-bezier(0.23, 1, 0.32, 1);
}
.Cursor-Main .Cursor-Main--sound > span.show {
    visibility: visible;
    opacity: 1;
}

// active states
.Cursor-Main.cursor--clicking {
    background-color:rgba(0,157,245,0.75);
}
.Cursor-Main.cursor--clicking.cursor--focused-volume .Cursor-Main--sound {
    color:rgba(0,157,245,1)
}
.Cursor-Border.cursor--clicking {
    border-color:rgba(0,157,245,0.75);   
}