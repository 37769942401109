@import 'variables';
@import 'mixins';
@import 'colors';
@import 'fonts';
@import 'typography';
@import 'aside';
@import 'navigation';
@import 'header';
@import 'footer';

.hide {
    display: none !important;
}

.hide-on-tablet {
    @media only screen and (max-width: 1024px) { 
        display: none;
    }
}