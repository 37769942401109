nav {
    width: 50px;
    margin: calc(50vh - 18px*$sections - 44px) auto 0;
}
nav > a {
    display: block;
    width: 18px;
    height: 18px;
    margin: 0 auto 18px;
    position: relative;
    cursor: none;
    pointer-events: none;
}
nav > a::before { //outside line
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -1px;
    left: -1px;
    content: '';
    border-radius: 40px;
    opacity: 0;
    border: 1px solid #009DF5;
    scale: 0.7;
    transition: all 0.5s;
}
nav > a::after { // static dot
    background-color: #dedede;
    display: block;
    content: '';
    border-radius: 40px;
    position: absolute;
    width: 8px;
    height: 8px;
    top: 4px;
    left: 4px;
    transition: all 0.5s;
}

nav > a.active::before {
    opacity: 1;
    scale: 0.7;
}

nav > a.active::after {
    background-color: #009DF5;
}

#downarrow {
    display: block;
    text-decoration: none;
    position: absolute;
    bottom: 44px;
    left: 50%;
    margin-left: -23px;
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    padding: 10px 14px 5px;
    opacity: 0;
    pointer-events: none;
    cursor: none;
}

#sound {
    padding-top: 44px;
    display: block;
    text-decoration: none;
    width: 22px;
    margin: auto;
    opacity: 0.5;
    transition: opacity 0.5s;

    > svg > path{
        stroke: #7d7d7d;
    }
    &.playing {
        opacity: 1;     
    }
}