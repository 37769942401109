.Clients-List {
    padding-left: 0;
    list-style-type: none;
    margin-top: 55px
}

.Client a {
    @extend h3;
    text-decoration: none;
    transition: color 1s cubic-bezier(0.23, 1, 0.32, 1);
    margin-bottom: 10px;
    display: inline-block;

    &:hover {
        color: #2D2D2D;
        font-style: italic;
    }
}
.Clients {
    overflow: hidden;
}
.Clients .bg {
    background: url('./../images/ui/circle-2x.png') no-repeat center left;
    background-size: 100%;
    width: 850px;
    height: 1000px;
    position: absolute;
    top: calc(50% - 550px);
    right: calc(-32px - 140px);
    pointer-events: none;
    opacity: 0.2;
    z-index: -10;
    &::after {
        content: '';
        mix-blend-mode: lighten;
        background: linear-gradient(145deg, rgb(240, 240, 240,0) 0%,  rgb(240, 240, 240, 1) 60%);
        display: block;
        border-radius: 100%;
        width: 852px;
        height: 826px;
        z-index: 0;
        top: 86px;
        left: 0;
        position: absolute;
    }
    display: none;
    @include conduct-desktop-large {
        display: block;
    }
}

.Clients-List li:nth-child(1){
    margin-left: -20%;
}
.Clients-List li:nth-child(2){
    margin-left: 0%;
}
.Clients-List li:nth-child(3){
    margin-left: 0%;
}
.Clients-List li:nth-child(4){
    margin-left: -25%;
}
.Clients-List li:nth-child(5){
    margin-left: 0%;
}
.Clients-List li:nth-child(6){
    margin-left: -22%;
}
.Clients-List li:nth-child(7){
    margin-left: 0%;
}
.Clients-List li:nth-child(8){
    margin-left: 0%;
}
.Clients-List li:nth-child(9){
    margin-left: -30%;
}
